* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--primary);
}

.appContainer {
  margin: 0 12% 0 12%;
}

h1,
h2 {
  color: var(--primary);
  font-family: "Etna";
  font-size: 2rem;
  @media screen and (max-width: 1000px) {
    font-size: 1.8rem;
  }
}

h3 {
  color: var(--primary);
  font-size: 1.5rem;
  line-height: 2rem;
  @media screen and (max-width: 1000px) {
    font-size: 1.5rem;
  }
}

h4 {
  color: var(--primary);
  font-size: 200%;
}

.wideText {
  font-family: "Agrandir Wide Light";
}

.maxw70 {
  width: 70%;
}

.maxw80 {
  width: 80%;
}

.maxw90 {
  width: 90%;
}

.color {
  color: var(--accent);
}

body {
  font-family: "Agrandir";
  margin: 0;
}

.container {
  min-height: 100vh;
  max-height: 100vh;
}

.TitleAtoutContainer {
  font-family: "Etna";
  background-color: var(--accent);
  margin: 7% 0;
  width: fit-content;
  height: fit-content;
  border: solid 5px black;
  display: flex;
  border-radius: 10px;
  box-shadow: var(--primary) 4px 4px;
  @media screen and (max-width: 1025px) {
    border: solid 3px black;
    border-radius: 5px;
    box-shadow: var(--primary) 2px 2px;
  }
  .TitleAtout {
    margin: 0 12px 5px 12px;
    font-size: 2rem;
    @media screen and (max-width: 1300px) {
      font-size: 1.6rem;
    }
    @media screen and (max-width: 1025px) {
      font-size: 1.2rem;
      margin: 0 8px 1px 8px;
    }
  }
}

.button {
  font-size: 1.2rem;
  margin: 7% 0 0 0;
  padding: 8px;
  transition: transform 500ms;
  width: 250px;
  height: fit-content;
  background-color: var(--secondary);
  border: solid 5px black;
  display: flex;
  border-radius: 10px;
  box-shadow: var(--primary) 4px 4px;
  @media screen and (max-width: 1000px) {
    font-size: 1rem;
    width: 200px;
  }

  i {
    padding-left: 5%;
  }
}

.button:hover {
  transform: scale(0.98);
}

.accentbtn {
  background: var(--accent);
  width: 60%;
  display: flex;
  justify-content: center;
}

.br {
  border-right: 3px solid var(--primary);
  margin: 0 10px;
}

.bb {
  border-bottom: 3px solid var(--primary);
  margin: 20px 0;
}

.TitleContainerAccent,
.TitleContainerSecondary {
  margin: 0 0 60px 0;
  width: fit-content;
  height: fit-content;
  border: solid 5px black;
  display: flex;
  border-radius: 10px;
  box-shadow: var(--primary) 4px 4px;
  .Title {
    margin: 0 12px 12px 12px;
    @media screen and (max-width: 900px) {
      margin: 8px 8px 4px 8px;
    }
  }
}
.TitleContainerAccent {
  background-color: var(--accent);
}

.TitleContainerSecondary {
  background-color: var(--secondary);
}
