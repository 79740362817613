.servicesContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin: 60px 0;

  .Container {
    display: flex;
    flex-flow: column nowrap;

    .presentationContainer {
      .titleServicesContainer {
        margin: 0 0 60px 0;
        width: fit-content;
        height: fit-content;
        border: solid 5px black;
        display: flex;
        background-color: var(--accent);
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: var(--primary) 4px 4px;

        .titleServices {
          margin: 8px;
          margin-top: 0;
        }
      }

      .presentation {
        font-size: 1.5rem;
        line-height: 2rem;

        margin-bottom: 5%;
        @media screen and (max-width: 900px) {
          font-size: 1rem;
        }
      }
    }

    //packs
    .containerPacks {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: flex-start;

      @media screen and (max-width: 900px) {
        align-items: center;
        width: 99.2vw;
        flex-flow: row nowrap;
        height: 350px;
        justify-content: flex-end;
        margin: 0 12% 0 12%;

        .swiper-wrapper {
          display: flex;
        }
        .swiper-slide {
          margin: 0;
        }
      }
      @media screen and (max-width: 720px) {
        height: 300px;
        .packContainer {
          // &:first-of-type {
          // margin-left: 210%;
          // }
          &:last-of-type {
            background: #000;
          }
        }
      }

      .packCom,
      .packPink {
        transition: transform 500ms;
        position: relative;
        display: flex;
        flex: 1 0 40%;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 2% 3%;
        height: 350px;
        min-height: 400px;
        color: var(--primary);
        border-radius: 25px;
        border: solid 5px var(--primary);
        box-shadow: 4px 4px var(--primary);
        // @media screen and (max-width: 1500px) {
        //   height: 300px;
        // }
        // @media screen and (max-width: 1050px) {
        //   height: 250px;
        // }
        @media screen and (max-width: 900px) {
          height: 300px;
          margin-bottom: 0;
          // flex: 1 0 70%;
        }
        @media screen and (max-width: 720px) {
          // min-width: 260px;
          height: 200px;
          // flex: 1 0 70%;
          border-radius: 15px;
        }

        .leftPart {
          display: flex;
          flex-flow: column nowrap;

          .titlePackContainer1,
          .titlePackContainer2 {
            font-family: "Etna";
            margin: 7% 0;
            width: fit-content;
            height: fit-content;
            border: solid 5px black;
            display: flex;
            border-radius: 10px;
            box-shadow: var(--primary) 4px 4px;
            @media screen and (max-width: 1025px) {
              border: solid 4px black;
              border-radius: 10px;
              box-shadow: var(--primary) 2px 2px;
            }

            .titlePack {
              margin: 2px 12px 10px 12px;
              font-size: 1.8rem;
              @media screen and (max-width: 1300px) {
                font-size: 1.4rem;
              }
              @media screen and (max-width: 720px) {
                margin: 4px 8px;
              }
            }
          }
          .titlePackContainer1 {
            background-color: var(--accent);
          }
          .titlePackContainer2 {
            background-color: var(--secondary);
          }

          .offrePackContainer {
            margin: 10% 0 10% 0;
            .offrePack {
              font-size: 1.2rem;
              @media screen and (max-width: 720px) {
                font-size: 1rem;
              }
            }
          }

          .buttonPack {
            background: none;
            color: var(--primary);
            border: 0px solid;
            position: absolute;
            bottom: 10%;
            text-align: start;
            font-size: 1rem;
            cursor: pointer;
            transition: transform 500ms;

            i {
              margin-right: 10px;
              transform: rotate(-35deg);
              transition: transform 500ms;
            }
          }
          .buttonPack:hover {
            transform: scale(1.1);
            i {
              transform: rotate(0deg);
            }
          }
        }
        .rightPart {
          font-size: 16.5rem;
          display: flex;
          width: 50%;
          height: 100%;
          flex-flow: row;
          justify-content: flex-end;
          align-items: center;
          @media screen and (max-width: 1300px) {
            font-size: 12rem;
          }

          .ImgId {
            height: 70%;
            // @media screen and (max-width: 1500px) {
            //   height: 60%;
            // }
            // @media screen and (max-width: 1050px) {
            //   height: 60%;
            // }
            // @media screen and (max-width: 720px) {
            //   height: 60%;
            // }
          }
        }
      }
      .packCom {
        background-color: var(--accent);
        @media screen and (max-width: 900px) {
          &:nth-child(3) {
            background-color: var(--secondary);
            .titlePackContainer2 {
              background-color: var(--accent);
            }
          }
        }
      }
      .packPink {
        background-color: var(--secondary);
        @media screen and (max-width: 900px) {
          &:nth-child(4) {
            background-color: var(--accent);
            .titlePackContainer1 {
              background-color: var(--secondary);
            }
          }
        }
      }
      .packCom:hover,
      .packPink:hover {
        transform: scale(1.05);
        @media screen and (max-width: 900px) {
          transform: scale(1.02);
        }
      }
    }
    .containerPacks::-webkit-scrollbar {
      display: none; /* Masquer la barre de défilement */
    }
  }
}

//mobile
.packMobileContainer {
  margin-top: 45px;
}
.packMobile {
  border: 5px solid black;
  box-shadow: 4px 4px black;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

  .topPart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
    i {
      font-size: 20px;
    }
  }

  .bottomPart {
    margin-top: 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    .bottomLeftPart {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      align-items: flex-start;
      .textDetail {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        i {
          font-size: 14px;
          color: var(--accent);
          margin-right: 5px;
        }
      }
    }

    .priceMobileContainer {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      font-family: "Agrandir Wide Black";
      .contactMsg {
        font-size: 14px;
        font-family: "Agrandir";
        .msg {
          text-decoration: underline;
        }
      }
    }
  }
}
