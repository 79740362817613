.atout {
  .icon {
    width: 30%;
    font-size: 150%;
    min-height: 20%;
    max-height: 30%;
  }

  .title {
    width: 70%;
    font-family: "Etna";
  }

  .text {
    width: 70%;
    margin-top: 5%;
  }
}
