.mentionsContainer {
  margin: 60px 0;
  p {
    line-height: 1.5rem;
    margin-top: 50px;
  }

  ul {
    line-height: 2.5rem;
  }

  .soustitre {
    padding-left: 5%;
    line-height: 1.5rem;
  }
}
