.PresentationContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
  @media screen and (max-width: 1000px) {
    height: fit-content;
  }

  .PresentationPart1 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }

  .Illustration1 {
    // width: 35%;
    min-width: 350px;
    max-height: 350px;
    @media screen and (max-width: 1000px) {
      display: none;
    }
    @media screen and (min-width: 1700px) {
      max-height: 500px;
    }
  }
}

//Section Atouts

.AtoutsContainer {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 60px;
  @media screen and (max-width: 900px) {
    justify-content: space-between;
    margin-bottom: 35px;
  }
  @media screen and (max-width: 720px) {
    flex-flow: column nowrap;
  }

  .Atout {
    border: solid 5px var(--primary);
    border-radius: 10px;
    width: 30%;
    box-shadow: var(--primary) 5px 5px;
    padding: 1% 2%;
    transition: transform 500ms;
    @media screen and (max-width: 900px) {
      width: 48%;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 720px) {
      width: 100%;
      margin-bottom: 30px;
      padding: 2% 6%;
    }

    .IconTitleContainer {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      i {
        font-size: 2rem;
        width: 20%;
        margin-right: 5%;
        @media screen and (max-width: 1025px) {
          margin-right: 0%;
          width: 30%;
        }
        @media screen and (max-width: 720px) {
          margin-right: 0%;
          width: 20%;
        }
      }
    }

    .TextAtoutContainer {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      p {
        width: 75%;
        font-size: 1.2rem;
        margin-bottom: 5%;
        @media screen and (max-width: 1300px) {
          font-size: 1rem;
          width: 70%;
        }
        @media screen and (max-width: 720px) {
          font-size: 1rem;
          width: 80%;
        }
      }
    }
  }
  .Atout:hover {
    transform: scale(1.08);
  }
}

// Section service
.ServiceContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
  @media screen and (max-width: 1000px) {
    height: fit-content;
  }

  .ServicePart1 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
  .Illustration2 {
    min-width: 350px;
    max-height: 350px;
    @media screen and (max-width: 1000px) {
      display: none;
    }
    @media screen and (min-width: 1700px) {
      max-height: 500px;
    }
  }
}

// Section RS
.socials {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;

  @media screen and (max-width: 1000px) {
    margin-bottom: 60px;
  }

  .cards {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card1,
    .card2,
    .card3 {
      cursor: pointer;
      box-shadow: var(--primary) 5px 5px;
      height: 100%;
      border: 5px solid var(--primary);
      width: 25%;
      background: var(--secondary);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: transform 500ms;
      font-size: 50px;
      padding: 5%;
      @media screen and (max-width: 1000px) {
        height: 50%;
        p {
          display: none;
        }
      }
      i {
        font-size: 4rem;
        @media screen and (max-width: 1000px) {
          font-size: 2rem;
        }
      }
      p {
        margin-top: 5%;
        font-size: 2rem;
      }
    }
    .card1:hover {
      transform: scale(1.02);
      background-image: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
      color: white;

      transition: 500ms ease-in-out;
      // font-size: 60px;
    }
    .card2:hover {
      transform: scale(1.02);
      background: rgb(40, 175, 252);
      background: linear-gradient(
        90deg,
        rgba(40, 175, 252, 1) 0%,
        rgba(20, 115, 228, 1) 100%
      );
      color: white;
      transition: 500ms ease-in-out;
      // font-size: 60px;
    }
    .card3:hover {
      transform: scale(1.02);
      background-color: #0073b2;
      color: white;

      transition: 500ms ease-in-out;
      // font-size: 60px;
    }
  }
}

.borderCard {
  border-radius: 20px;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
