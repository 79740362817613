.agencyContainer {
  margin: 50px 0;

  p {
    text-align: justify;
  }

  .middleContainer {
    width: 20%;

    .verticalBorder {
      border-left: 3px solid var(--primary);
    }
  }

  .stepContainer {
    position: relative;
    width: 100%;

    .topBorder {
      border-top: 3px solid var(--primary);
      width: 20%;
    }

    .blankSpace {
      width: 20%;
    }

    .stepText,
    .stepNumber {
      width: 40%;

      .TitleDateContainer {
        margin: 0 0 7% 0;
        width: fit-content;
        height: fit-content;
        background-color: var(--secondary);
        border: 4px solid var(--primary);
        box-shadow: 4px 4px var(--primary);
        display: flex;
        border-radius: 10px;

        .TitleDate {
          margin: 0 12px 12px 12px;
          color: var(--primary);
          font-family: "Etna";
          @media screen and (max-width: 900px) {
            margin: 5px;
          }
        }
      }

      .TextDate {
        font-size: 1.2rem;
        margin: 0 0 5% 0;
        @media screen and (max-width: 900px) {
          font-size: 1rem;
        }
      }

      .ButtonChronoModal {
        margin-bottom: 20px;
        background-color: var(--accent);
        height: 32px;
        width: fit-content;
        padding: 5px;
        border-radius: 5px;
        border: 3px solid var(--primary);
        box-shadow: 3px 3px var(--primary);
        font-family: "Agrandir Wide Light";
        font-size: 1rem;
        cursor: pointer;
        color: var(--primary);
        transition: transform 300ms;
      }
      .ButtonChronoModal:hover {
        transform: scale(0.98);
      }
      .ButtonChronoModal:active {
        transform: scale(0.98);
      }
    }

    .stepNumber {
      font-size: 30rem;
      opacity: 0.25;
      //CHECK HERE FOR THE CSS NUMBER AND SPACE BEFORE AND AFTER TEXT
      line-height: 90%;
    }
  }
}

@media screen and (max-width: 900px) {
  .TitleDateContainer {
    margin-top: -20px !important;
  }

  .stepContainer {
    width: 100%;
  }
  .stepText {
    width: 100% !important;
    margin-bottom: 50px;
  }
}
