.header {
  max-height: 100px;
  border-bottom: 3px solid var(--primary);

  @media screen and (max-width: 830px) {
    visibility: hidden;
    display: none;
  }

  a {
    text-decoration: none;
  }

  .logo {
    height: 30px;
    margin: 20px;
    @media screen and (max-width: 830px) {
      margin: 20px 20px 20px 0;
      height: 25px;
    }
  }

  .headerLink {
    font-size: 1.2rem;
    transition: transform 200ms;
    @media screen and (max-width: 830px) {
      display: none;
      visibility: hidden;
    }
  }
  .headerLink:hover {
    transform: scale(1.05);
  }
  .headerLink.active {
    text-decoration: underline;
    // text-decoration-color: var(--accent);
    // text-decoration-thickness: 2px;
  }
}

.hamburger {
  // z-index: 100;
  height: fit-content;
  cursor: pointer;
  display: flex; /* Utilisation de flexbox pour centrer le contenu */
  align-items: center; /* Centrage vertical */
  justify-content: center; /* Centrage horizontal */
  @media screen and (min-width: 830px) {
    display: none;
    visibility: hidden;
  }
}

.hamburger input {
  display: none;
}
.hamburger svg {
  /* The size of the SVG defines the overall size */
  height: 3em;
  /* Define the transition for transforming the SVG */
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: var(--primary);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  /* Define the transition for transforming the Stroke */
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
  transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}

.menuopen {
  background: #000;
  height: 100vh;
  transition: ease-in-out 0.5;
}

//header burger

.headerBurger {
  @media screen and (min-width: 831px) {
    width: 100vw;
    display: none;
  }

  .headerBurgerContainer {
    i {
      font-size: 25px;
    }
    i:hover {
      transform: rotate(-180deg);
      transition: transform 500ms;
    }

    .logo {
      margin: 20px 20px 20px 0;
      height: 25px;
    }

    .menuContainer {
      margin: 20px 0 20px 20px;
    }
    .menuContainer1 {
      margin: 20px 0 20px 20px;
      transition-duration: 2s;

      .list {
        position: fixed;
        padding: 0 12% 0 12%;
        height: 100vh;
        width: 100%;
        background-color: var(--secondary);
        right: 0;
        top: 0;
        overflow-y: hidden;
        display: flex;
        flex-flow: column nowrap;
        z-index: 100;

        .cross {
          margin-top: 20px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 20%;
        }

        .ulContainer {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          ul {
            list-style: none;
            width: 100%;
            li {
              margin-bottom: 30px;
              font-size: 1.2rem;

              .headerLink.active {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}
