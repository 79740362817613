.backgroundModalChrono {
  z-index: 5000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);

  .modalChrono {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5%;
    z-index: 6000;
    position: absolute;
    padding: 10vh;
    width: 40vw;
    height: fit-content;
    background-color: var(--secondary);
    border: 4px solid black;
    box-shadow: 5px 5px black;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    @media screen and (max-width: 900px) {
      width: 75vw;
      height: fit-content;
      padding: 5vh;
    }
    @media screen and (max-width: 720px) {
      width: 75vw;
      height: fit-content;
      padding: 5vh;
    }

    p {
      padding-right: 2vw;
      @media screen and (max-width: 900px) {
        padding-right: 10vw;
        font-size: 13px;
      }
    }

    button {
      width: 5vw;
      position: absolute;
      right: 5vh;
      top: 5vh;
      border: none;
      background: none;
      transition: transform 0.3s;

      img {
        @media screen and (max-width: 900px) {
          width: 20px;
        }
      }
    }
    button:hover {
      transform: scale(1.1);
      cursor: pointer;
    }

    h2 {
      margin-bottom: 3%;
    }

    &.activeModal {
      opacity: 1;
      visibility: visible;
    }
  }
}
