.contactContainer {
  margin-bottom: 60px;
  margin-top: 60px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 900px) {
    // width: 100%;
    display: flex;
    flex-flow: column nowrap;
  }

  .leftPartContact {
    display: flex;
    flex-flow: column nowrap;

    .infosContactContainer {
      .contactSubtitle {
        margin-bottom: 30px;
      }
      .infoContact {
        margin-bottom: 10px;
        display: flex;
        flex-flow: row nowrap;

        i {
          margin-right: 10px;
          color: var(--accent);
        }
      }
    }
  }

  .rightPart {
    width: 50%;
    input:not([type="checkbox"]),
    textarea {
      all: unset;
    }
    @media screen and (max-width: 900px) {
      width: 100%;
      margin-top: 30px;
    }

    .error {
      color: var(--accent);
      margin-bottom: 10px;
    }
    .contactForm {
      display: flex;
      flex-flow: column;
      width: 100%;
      .nomPrenomContainer {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
        .inputContainer {
          display: flex;
          flex-flow: column nowrap;
          width: 48%;
          @media screen and (max-width: 720px) {
            width: 45%;
          }
        }
      }
      .contactInput,
      .contactInputMsg,
      .contactInputNomPrenom {
        border: 5px solid black;
        border-radius: 10px;
        box-shadow: 2px 2px black;
        height: 5vh;
        color: black;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
        transition: all 500ms;
      }
      .contactInput:focus,
      .contactInputMsg:focus,
      .contactInputNomPrenom:focus {
        box-shadow: 5px 5px black;
      }
      .contactInputMsg {
        height: 20vh;
        padding-top: 10px;
        overflow-wrap: break-word;
      }
    }

    .buttonFormContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      .buttonForm {
        border: 4px solid black;
        width: 60%;
        height: 40px;
        background-color: var(--secondary);
        border-radius: 5px;
        font-family: "Etna";
        font-size: 15px;
        margin: 30px 0 0 0;
        padding-bottom: 5px;
        transition: background-color 500ms;
        color: black;
        @media screen and (max-width: 900px) {
          padding: 0;
        }
      }
      .buttonForm:active {
        transform: scale(0.95);
      }
      .buttonForm:hover {
        background-color: var(--accent);
      }
    }

    .consentContainer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      .consentCheckbox {
        margin-right: 2%;
      }
    }

    .cguLinks {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
