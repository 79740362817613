//alignements flex

.flex {
  display: flex;
}

.flexc {
  flex-direction: column;
}

.flexf {
  flex-flow: row wrap;
}

.jcc {
  justify-content: center;
}

.jca {
  justify-content: space-around;
}

.jcb {
  justify-content: space-between;
}

.jce {
  justify-content: space-evenly;
}

.jcfe {
  justify-content: flex-end;
}

.jcfs {
  justify-content: flex-start;
}

// text align
.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.taj {
  text-align: justify;
}

.aic {
  align-items: center;
}

.ais {
  align-items: flex-start;
}

//margin
.mauto {
  margin: auto;
}

.mt5 {
  margin-top: 5px;
}

.m10 {
  margin: 10px auto;
}

.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}
.mb5p {
  margin-bottom: 5%;
}

.m20 {
  margin: 20px auto;
}

.mt20 {
  margin-top: 20px;
}
.m30 {
  margin: 30px auto;
}

.mr5p {
  margin-right: 5%;
}

.mb30 {
  margin-bottom: 30px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt50 {
  margin-top: 50px;
}

.mt5 {
  margin-top: 5%;
}

.mb50 {
  margin-bottom: 50px;
}

.mb5 {
  margin-bottom: 5%;
}

.m50 {
  margin: 50px;
}

.mt60 {
  margin-top: 60px;
}

.maw {
  max-width: 300px;
}

.max80 {
  max-width: 80%;
  margin: auto;
}

.max60 {
  max-width: 50%;
  // margin: auto;
}
.max50 {
  max-width: 50%;
  margin: auto;
}

.max40 {
  max-width: 40%;
  margin: auto;
}

//padding
.ps10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ps20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.hfc {
  height: fit-content;
}

.mt60 {
  margin-top: 60px;
}
.mb60 {
  margin-bottom: 60px;
}

.mlR12 {
  @media screen and (max-width: 900px) {
    margin-left: 12%;
  }
}
.mrR12 {
  @media screen and (max-width: 900px) {
    margin-right: 12%;
  }
}
.mR0 {
  @media screen and (max-width: 900px) {
    margin-right: 0;
  }
}
