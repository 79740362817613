.backgroundBurger {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);
}

.modalBurger {
  padding: 50% 12% 0 12%;
  z-index: 11;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--secondary);
  opacity: 0;
  visibility: hidden;
  font-size: 1.6rem;
  text-align: center;

  ul {
    list-style: none;
  }

  button {
    position: absolute;
    right: 2%;
    top: 10%;
    border: none;
    background: none;
    transition: transform 0.3s;
  }
  button:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  h2 {
    margin-bottom: 3%;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.hamburger {
  // background: #000;
}
