.ScrollToTopButton {
  background-color: var(--secondary);
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10000;
  border: 3px solid black;
  border-radius: 5px;
  box-shadow: 3px 3px black;
  height: 5vh;
  width: 5vh;

  i {
    font-size: 3vh;
    color: black;
  }
}
