//background blur
.backgroundModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);

  //modal
  .modalServ {
    overflow-x: hidden;
    padding: 10vh;
    z-index: 5000;
    position: fixed;
    bottom: 5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 70vh;
    background-color: var(--secondary);
    border: 4px solid black;
    box-shadow: 5px 5px black;
    border-radius: 10px;
    @media screen and (max-width: 900px) {
      width: 50vw;
    }
    @media screen and (max-width: 720px) {
      width: 75vw;
      height: 55vh;
      padding: 5vh;
    }

    .modalServContainer {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;

      //Titre
      .titleContainer {
        width: fit-content;
        height: fit-content;
        background-color: var(--accent);
        display: flex;
        border-radius: 10px;
        border: 4px solid black;
        box-shadow: 3px 3px black;
        @media screen and (max-width: 900px) {
        }
        .titleServModal {
          margin: 4px 8px;
          font-family: "Etna";
          font-size: 1.4rem;
          @media screen and (max-width: 900px) {
            font-size: 1.2rem;
          }
        }
      }

      //details
      .detailContainer {
        margin-top: 5%;
        .detail {
          display: flex;
          margin-bottom: 1%;
          p {
            font-size: 1rem;
            margin-left: 2%;
          }
          i {
            font-size: 1rem;
            color: var(--accent);
          }
        }
      }

      //modalité
      .modalityContainer {
        margin-top: 3%;
        width: 80%;
        font-size: 0.8rem;
        color: grey;
      }

      // prix
      .priceContainer {
        margin-top: 3%;
        .price {
          font-size: 1.3rem;
        }
      }
    }

    //bouton de fermeture
    button {
      position: absolute;
      right: 10vh;
      top: 10vh;
      border: none;
      background: none;
      transition: transform 0.3s;
      @media screen and (max-width: 720px) {
        right: 5vh;
        top: 5vh;
      }
      img {
        width: 1.2rem;
      }
    }
    button:hover {
      transform: scale(1.1);
      cursor: pointer;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .servicesNavlink {
      color: var(--accent);
      text-decoration: underline;
    }
  }
}
